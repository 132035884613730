// extracted by mini-css-extract-plugin
export var detailTtl = "_recruit-module--detailTtl--B9dDL";
export var detailTxt = "_recruit-module--detailTxt--4O821";
export var detailListTtl = "_recruit-module--detailListTtl--nouTQ";
export var careerNewgradWrapper = "_recruit-module--careerNewgradWrapper--0xVOz";
export var careerNewgradContent = "_recruit-module--careerNewgradContent--W51S3";
export var careerNewgradLink = "_recruit-module--careerNewgradLink--xWNJZ";
export var careerNewgradTtl = "_recruit-module--careerNewgradTtl--BWRmj";
export var positionWrapper = "_recruit-module--positionWrapper--r7KY3";
export var positionContents = "_recruit-module--positionContents--MPANg";
export var positionLink = "_recruit-module--positionLink--rXoJ6";
export var position = "_recruit-module--position--GDv5D";
export var positionTxt = "_recruit-module--positionTxt--3I1VR";
export var processWeb = "_recruit-module--processWeb--ToLlC";
export var processMobile = "_recruit-module--processMobile--KBUnP";
export var recruitSlider = "_recruit-module--recruitSlider--5qyQq";
export var space = "_recruit-module--space--GAiDy";